<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="700px">
      <v-card>
        
        <v-card-title v-if="type === 'MU1'">multi set 1<v-spacer></v-spacer><v-icon :color="color.theme" dense dark size="35">mdi-server</v-icon></v-card-title>
        <v-card-title v-else-if="type === 'MU2'">multi set 2<v-spacer></v-spacer><v-icon :color="color.theme" dense dark size="35">mdi-server</v-icon></v-card-title>
        <v-card-title v-else-if="type === 'MU3'">multi set 3<v-spacer></v-spacer><v-icon :color="color.theme" dense dark size="35">mdi-server</v-icon></v-card-title>
    
        <v-divider></v-divider>
      <v-card-text>

        <v-card class=" mt-3 ml-10 mr-10  elevation-0" >
          <v-card-title class="pb-0"><v-icon class="pr-2">mdi-city</v-icon>{{ $t('quota_storage.quota_business')}}</v-card-title>
          <v-card-text class="pb-0 pt-2">
             <v-layout class="pl-16 pr-16 ml-16 mr-16 ">
               <v-progress-circular  v-if="percent_remain_bus > 40" :rotate="90" :width="16" :size="100" :value="percent_remain_bus" color="light-green"><strong  class="caption font-weight-light" >{{ percent_remain_bus }}%</strong></v-progress-circular>
               <v-progress-circular  v-else-if="percent_remain_bus <= 40 && percent_remain_bus > 10"  :rotate="90" :width="16" :size="100" :value="percent_remain_bus" color="amber"><strong  class="caption font-weight-light" >{{ percent_remain_bus }}%</strong></v-progress-circular>
                <v-progress-circular  v-else-if="percent_remain_bus <= 10"  :rotate="90" :width="16" :size="100" :value="percent_remain_bus" color="red"><strong  class="caption font-weight-light" >{{ percent_remain_bus }}%</strong></v-progress-circular>
            <v-list two-line subheader>
              <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="remain_bus"></v-list-item-title>
              <v-list-item-subtitle v-text="$t('quota_storage.text_remain_bus')"></v-list-item-subtitle>
              </v-list-item-content>
              </v-list-item>
              </v-list>             
            </v-layout>
          </v-card-text>
        </v-card>
        <v-card class=" mt-3 ml-10 mr-10 mb-2 elevation-2">
          <v-card-title><v-icon class="pr-2">settings</v-icon>{{ $t('quota_storage.setstorage')}}</v-card-title>
          <v-card-text>
            <v-list two-line dense>
            <v-list-item
                v-for="(item, i) in datamultiplesetting"
                :key="i"
                hide-details
                
            >
            <v-list-item-content v-if="item.department_name">
                <v-list-item-title v-text="item.department_name"></v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content v-else>
                <v-list-item-title v-if="$t('default') === 'en'" v-text="item.user_name_en"></v-list-item-title>
                <v-list-item-title v-else v-text="item.user_name_th"></v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action >
                <v-text-field
              v-model="item.quota_storage_num"
              :label="$t('quota_storage.text_edit')"
              dense
              outlined
              hide-details
              type="number"
              min="0"
              @change="checkstorage(item.quota_storage_num,item.quota_storage_unit)"
            ></v-text-field>          
            <v-select @change="checkstorage(item.quota_storage_num,item.quota_storage_unit)"  hide-details class="pt-1" v-model="item.quota_storage_unit" :items="itemsize" outlined dense></v-select></v-list-item-action>
            </v-list-item>
        </v-list>
          </v-card-text>
        </v-card>
        
      </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="close()">{{ $t("admin.close") }}</v-btn>
          <v-btn :color="color.theme" :dark="color.darkTheme" @click="fn_update_storge()" :loading="loadprogress">{{ $t("admin.submit") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required} from "vuelidate/lib/validators";

import gbfconvetUnit from "@/globalFunctions/sizeDataConverter";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    filename: { required },
  },
  props: ["show", "datamultiplesetting", "remain_business","type","percent_remain"],
  data: function() {
    return {
        percent_remain_bus:0,
       remain_bus:"",
      loadprogress:false,
      selectsize: "GB",
      itemsize: ["KB", "MB", "GB", "TB"],
      quota_storage:"",
      remain_storage:"",
      used_storage:"",
      edit_storage:0,
      storau: "1 GB",
      storan: 50,
      selectSize: "",
      filename: "",
      createprogress: false,
      zoom: 0,
      test: 1,
      unitremain: "",
      updatestorage: 0,
      maxstorage: 0,
      remain_storage: 0,
      sizeStorage: ["MB", "GB"],
      totalstorage: 0,
      unit_storage_account: "",
      plusnum: 1,
      maxstorageupdate: "",
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
        }
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
          this.remain_bus = this.remain_business;
          this.percent_remain_bus = this.percent_remain;
        console.log(this.datamultiplesetting);
      } else {
        this.$emit("closemanagestorage");
      }
    },
  },
  methods: {
    close() {
      this.$emit("closemanagestorage");
      this.test = 1;
    },
    convertstorage(parameter){
      let res_ = parameter.split(" ");
      this.edit_storage = parseFloat(res_[0]);
      this.selectsize = res_[1]
      console.log( this.edit_storage );
    },
   async fn_update_storge(){
      this.loadprogress = true
        let id;
        let type="";
      if(this.type === "de"){
        id = this.datastorage["department_id"]
        type = "department"
      }else{
        id = this.datastorage["account_user"]
        type = "employee"
      }
      var payload = {
        account_admin: this.dataAccountId,
        id: id,
        business_id: this.dataAccountActive.business_info.business_id,
        unit: this.selectsize,
        storage: this.edit_storage.toString(),
        type:type
      }
      let auth = await gbfGenerate.generateToken();
     await this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/admin/update_storage_emlpoyee_or_department", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
          console.log("file", response);
       
          if (response.data.status === "OK") {
            this.loadprogress = false;
             this.$emit("closemanagestorage");
             Toast.fire({
              icon: "success",
              title: response.data.message
            });
          }else{
            this.loadprogress = false;
              Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
          }
        }).catch((error) => {
          this.loadprogress = false;
          Toast.fire({
            icon: "error",
            title: error,
          });
          
        });
    },
    checkstorage(storage,unit){
        let test = storage.toString()+" "+unit;
        let check = gbfconvetUnit.convertStorageToByte(this.remain_bus) - gbfconvetUnit.convertStorageToByte(test);
        this.remain_bus = gbfconvetUnit.convertStorageToKbMbGb(check)
        this.percent_remain_bus = Math.round((check/gbfconvetUnit.convertStorageToByte(this.remain_bus))*100)
        console.log(gbfconvetUnit.convertStorageToKbMbGb(check));
        if(check < 0){
            this.remain_bus = "0"
            this.percent_remain_bus = 0
             Toast.fire({
            icon: "error",
            title: "storage ไม่พอโว้ยยยยยยยย",
          });
        }

    }
  },
};
</script>
<style></style>
